<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        @back="hRedirect('faq-categories-list')"
        @save="createItem"
        :title="this.currentItem.name"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.stop.prevent="handleSubmit(createItem)">
        <b-card>
          <b-row>
            <b-col md="12" xl="12" class="mb-1 mt-2">
              <b-form-checkbox v-model="currentItem.active" value="1" name="active"
                               class="custom-control-success"
              >
                {{ $t('Active') }}
              </b-form-checkbox>
            </b-col>
            <b-col md="6" class="mb-1 mt-2">
              <b-form-group :label="$t('Area')">
                <b-form-select
                    v-model="currentItem.area"
                    :options="areas"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" xl="6">
              <validation-provider #default="validationContext" :name="$t('Name')" rules="required">
                <b-form-group :label="$t('Name')">
                  <b-form-input v-model="currentItem.name"
                                id="firstname"
                                :placeholder="$t('Name')"
                  />

                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  <small v-if="validate && Object.keys(validation).includes('name')" class="text-danger">{{ $t(validation.name[0]) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="6">
              <validation-provider #default="validationContext" :name="$t('Position')" rules="required">
                <b-form-group :label="$t('Position')">
                  <b-form-input v-model="currentItem.position"
                                id="position"
                                :placeholder="$t('Position')"
                                type="number"
                  />

                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  <small v-if="validate && Object.keys(validation).includes('position')" class="text-danger">{{ $t(validation.position[0]) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-button variant="success" type="submit">
            {{ $t('Add Category') }}
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormFile,
  BAvatar,
  BMedia,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import Cleave from 'vue-cleave-component'
import { required, alphaNum, email } from '@validations'
import moment from 'moment'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    vSelect,
    BFormFile,
    BAvatar,
    BMedia,
    BInputGroupAppend,
    BInputGroup,
    Cleave,
    BFormSelect,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      name: '',
      currentItem: {
        id: '',
        name: '',
        position: 0,
        active: 1,
        area: 'expert'
      },
      areas: [
        {
          text: this.$t('Client'),
          value: 'client'
        },
        {
          text: this.$t('Expert'),
          value: 'expert'
        }
      ],
      loaded: true,
      validation: {},
      validate: false,
    }
  },
  computed: {},

  methods: {
    async createItem() {
      this.helperAddItem(`/faq/categories`, this.currentItem, this.$refs.refFormObserver, {name: 'faq-categories-list' })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },
  },
  mounted() {

  },
}
</script>

